var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBody
    ? _c(
        "div",
        { staticClass: "flex flex-col justify-center" },
        [
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c(
              "div",
              { staticClass: "flex justify-between" },
              [
                _c("BackNav", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                }),
                _c("p", { staticClass: "text-lightGrey-2 text-sm" }, [
                  _vm._v("1 of 1"),
                ]),
              ],
              1
            ),
          ]),
          _c("h5", { staticClass: "md:text-2xl text-xl font-bold text-dark" }, [
            _vm._v(" Connect bank account "),
          ]),
          _c("p", { staticClass: "text-grey text-lg" }, [
            _vm._v(
              " This should be an account with adequate cashflow, preferably your salary account "
            ),
          ]),
          _c("p", { staticClass: "text-grey mt-6" }, [
            _vm._v(
              " You will be asked to log in using your internet banking credentials. Full disclosure, here’s what we will be doing: "
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "mt-2 bg-white border border-lightGrey-1 shadow payment-space rounded-lg min-h-56 w-full p-6",
            },
            _vm._l(_vm.rules, function (points, index) {
              return _c(
                "div",
                { key: index, staticClass: "mt-4 flex items-center" },
                [
                  _c("div", {
                    staticClass: "icon-container mr-3",
                    domProps: { innerHTML: _vm._s(points.icon) },
                  }),
                  _c("p", { staticClass: "text-dark" }, [
                    _vm._v(_vm._s(points.title)),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm.queryResult.dataStatus === _vm.dataStatus.pending
            ? _c("Button", {
                staticClass: "mt-8",
                attrs: { text: "Close" },
                on: { click: _vm.handleClose },
              })
            : _vm._e(),
          _c("Button", {
            staticClass: "mt-8",
            attrs: {
              loading: _vm.loading,
              text: "Connect bank account",
              width: "w-full",
            },
            on: { click: _vm.handleConnectBank },
          }),
          _vm._m(0),
          _c(
            "Modal",
            {
              attrs: {
                display: _vm.displayValidationStatus,
                hideClose: "",
                size: "large",
              },
            },
            [
              _c("VerificationStatus", {
                attrs: { queryResult: _vm.queryResult },
                on: {
                  back: function ($event) {
                    _vm.displayValidationStatus = false
                    _vm.$emit("back")
                  },
                  connectBank: _vm.handleConnectBank,
                  success: _vm.handleSuccess,
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-grey mt-4 text-center" }, [
      _vm._v(" ** You will be redirected to "),
      _c("span", { staticClass: "semibold" }, [_vm._v("Mono")]),
      _vm._v(" to add your bank details "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }