import { render, staticRenderFns } from "./BankLogin.vue?vue&type=template&id=160f1bfa&scoped=true&"
import script from "./BankLogin.vue?vue&type=script&lang=js&"
export * from "./BankLogin.vue?vue&type=script&lang=js&"
import style0 from "./BankLogin.vue?vue&type=style&index=0&id=160f1bfa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160f1bfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('160f1bfa')) {
      api.createRecord('160f1bfa', component.options)
    } else {
      api.reload('160f1bfa', component.options)
    }
    module.hot.accept("./BankLogin.vue?vue&type=template&id=160f1bfa&scoped=true&", function () {
      api.rerender('160f1bfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Home/CreditQualification/Bank/BankLogin.vue"
export default component.exports