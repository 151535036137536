<template>
  <div class="flex flex-col justify-center" v-if="showBody">
    <div class="grid grid-cols-2">
      <div class="flex justify-between">
        <BackNav @click="$emit('back')" />
        <p class="text-lightGrey-2 text-sm">1 of 1</p>
      </div>
    </div>
    
    <h5 class="md:text-2xl text-xl font-bold text-dark">
      Connect bank account
    </h5>

    <p class="text-grey text-lg">
      This should be an account with adequate cashflow, preferably your salary
      account
    </p>
    <p class="text-grey mt-6">
        You will be asked to log in using your internet banking credentials.
        Full disclosure, here’s what we will be doing:
      </p>
    <div
      class="mt-2 bg-white border border-lightGrey-1 shadow payment-space rounded-lg min-h-56 w-full p-6"
    >
      <div
        class="mt-4 flex items-center"
        v-for="(points, index) in rules"
        :key="index"
      >
        <div class="icon-container mr-3" v-html="points.icon"></div>
        <p class="text-dark">{{ points.title }}</p>
      </div>
    </div>
    <Button
      class="mt-8"
      v-if="queryResult.dataStatus === dataStatus.pending"
      text="Close"
      @click="handleClose"
    />
    <Button
      class="mt-8"
      :loading="loading"
      text="Connect bank account"
      width="w-full"
      @click="handleConnectBank"
    />
    <p class="text-grey mt-4 text-center">
      ** You will be redirected to <span class="semibold">Mono</span> to add
      your bank details
    </p>
    <Modal :display="displayValidationStatus" hideClose size="large">
      <VerificationStatus
        @back="
          displayValidationStatus = false;
          $emit('back');
        "
        :queryResult="queryResult"
        @connectBank="handleConnectBank"
        @success="handleSuccess"
        @close="$emit('close')"
      />
    </Modal>
  </div>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import { Button } from "@/UI/Button";
  import { QUERY_ACCOUNT_HEALTH } from "@/utils/api/verification";
  import AnimateHeight from "@/UI/AnimateHeight";
  import { DATA_STATUS } from "@/utils/sharedData/home";
import { Modal } from "@/UI/Modals";
import { BackNav } from "@/UI/Navigation";

  export default {
    components: {
      Button,
      BackNav,
      AnimateHeight,
      Modal,
      VerificationStatus: () => import("./VerificationStatus.vue"),
    },
    data: () => ({
      showBody: true,
      loading: false,
      dataStatus: DATA_STATUS,
      queryTiming: {
        timeout: null,
        timeLeft: 120,
      },
      queryCounter: null,
      queryResult: {},
      queryCount: 0,
      rules: [
        {
          title: "Pulling transaction records from your account",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path d="M5.89688 11.1573V4.75726c0-.88.712-1.6 1.6-1.6h7.20002v-.8c0-.88-.72-1.6-1.6-1.6H1.89688c-.88801 0-1.6.72-1.6 1.6V13.5573c0 .88.71199 1.6 1.6 1.6H13.0969c.88 0 1.6-.72 1.6-1.6v-.8H7.49688c-.888 0-1.6-.72-1.6-1.6Zm2.4-6.40004c-.44 0-.8.36-.8.8v4.80004c0 .44.36.8.8.8h7.20002V4.75726H8.29688Zm2.40002 4.4c-.664 0-1.20002-.536-1.20002-1.2 0-.664.53602-1.2 1.20002-1.2s1.2.536 1.2 1.2c0 .664-.536 1.2-1.2 1.2Z" fill="#6956C8"/></svg>`,
        },
        {
          title: "Verifying your personal information",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="none"><path d="M13.0969.95727H1.89688c-.888 0-1.6.72001-1.6 1.60001V13.7573c0 .88.72 1.6 1.6 1.6h3.2l1.832 1.832c.312.312.816.312 1.128 0l1.84-1.832h3.20002c.88 0 1.6-.72 1.6-1.6V2.55728c0-.88-.72-1.6-1.6-1.6ZM7.49688 3.59729c1.192 0 2.16.968 2.16 2.16 0 1.192-.968 2.16-2.16 2.16-1.192 0-2.16-.968-2.16-2.16 0-1.192.968-2.16 2.16-2.16Zm4.80002 8.56002H2.69688v-.72c0-1.60002 3.2-2.48002 4.8-2.48002 1.6 0 4.80002.88 4.80002 2.48002v.72Z" fill="#6956C8"/></svg>`,
        },
        // {
        //   title: "Confirming if this is where your salary comes into",
        //   icon: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" fill="none"><path d="M10.9 10.1573h2.4c.44 0 .8-.36002.8-.80002v-4.8c0-.44-.36-.8-.8-.8h-2.4c-.44 0-.8.36-.8.8v4.8c0 .44.36.80002.8.80002Zm.8-4.80002h.8v3.2h-.8v-3.2ZM6.1 10.1573h2.4c.44 0 .8-.36002.8-.80002v-4.8c0-.44-.36-.8-.8-.8H6.1c-.44 0-.8.36-.8.8v4.8c0 .44.36.80002.8.80002Zm.8-4.80002h.8v3.2h-.8v-3.2Zm-3.2-1.6c-.44 0-.8.36-.8.8v4.8c0 .44.36.80002.8.80002.44 0 .8-.36002.8-.80002v-4.8c0-.44-.36-.8-.8-.8Zm-3.2-1.6v9.60002c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V2.15728c0-.88-.72-1.6-1.6-1.6H2.1c-.88 0-1.6.72-1.6 1.6Zm13.6 9.60002H2.9c-.44 0-.8-.36-.8-.8V2.95728c0-.44.36-.8.8-.8h11.2c.44 0 .8.36.8.8v8.00002c0 .44-.36.8-.8.8Z" fill="#6956C8"/></svg>`,
        // },
      ],
      displayValidationStatus: false,
    }),
    computed: {
      ...mapState({
        eligibleAmount: (state) =>
          state?.userInfo?.creditQualification?.eligibilityCriteriaResult
            .eligibleAmount,
      }),
    },
    methods: {
      ...mapActions("dashboard", [
        "linkBank",
        "verifyBank",
        "showCreditQualificationStep",
      ]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("userInfo", ["getCreditQualification", "getUserBanks"]),
      handleConnectBank() {
        this.queryTiming.timeLeft = 120;
        this.handleLinkBank();
        this.displayValidationStatus = false;
      },
      handleLinkBank() {
        this.queryResult = {};
        this.linkBank()
          .then((res) => {
            this.setLoading({
              display: true,
              title: "We are analysing your account",
              description:
                "This will only take some minutes. Please do not leave this sceeen.",
            });
            this.verifyBank(res)
              .then(({ data }) => {
                const accountNumber = data?.data?.accountNumber;
                const bankId = data?.data?.dbank?.id;
                this.queryAccount(accountNumber, bankId);
              })
              .catch(() => {
                this.setLoading(false);
              });
          })
          .catch(() => {
            this.loading = false;
          });
      },
      queryAccount(accountNumber, bankId) {
        // start the query Timeout
        if (this.queryTiming.timeLeft === 120) {
          this.startQueryTimeout();
        }
        // if the 2mins timeout has passed, end the operation
        if (this.queryTiming.timeLeft <= 0) {
          this.setLoading(false);
          this.queryResult.dataStatus = this.dataStatus.pending;
          this.displayValidationStatus = true;
        } else {
          QUERY_ACCOUNT_HEALTH({
            accountNumber: accountNumber,
            bankId: bankId,
          })
            .then(({ data }) => {
              // if your status is not pending
              if (data?.data?.dataStatus !== this.dataStatus.pending) {
                // get credit qualification status to get eligible amount
                this.getCreditQualification().then(() => {
                  this.queryResult = data?.data;
                  this.queryResult.eligibleAmount = this.eligibleAmount;
                  this.displayValidationStatus = true;
                  this.setLoading(false);
                });
                // if there's not a failed or available status from the query endpoint
              } else {
                // recurse the function
                this.queryCounter = setTimeout(() => {
                  this.queryAccount(accountNumber, bankId);
                }, 2000);
              }
            })
            .catch(() => {
              this.setLoading(false);
              if (this.queryTiming.timeLeft > 0) {
                clearInterval(this.queryTiming.timeout);
                this.queryTiming.timeLeft = 120;
              }
            });
        }
      },
      startQueryTimeout() {
        this.queryTiming.timeout = setInterval(() => {
          if (this.queryTiming.timeLeft <= 0) {
            clearInterval(this.queryTiming.timeout);
          }
          this.queryTiming.timeLeft -= 1;
        }, 1000);
      },
      handleClose() {
        this.showCreditQualificationStep(false);
      },
      handleSuccess() {
        this.$emit("success");
      },
    },
    destroyed() {
      clearTimeout(this.queryCounter);
      clearInterval(this.queryTiming.timeout);
    },
  };
</script>
<style scoped>
  .icon-container {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: #f7f5ff;
  }
</style>
